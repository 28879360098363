import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, vShow as _vShow, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_VueSpinner = _resolveComponent("VueSpinner")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!
  const _component_ToastsComponent = _resolveComponent("ToastsComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle({'font-size': `${_ctx.settingsStore.fontSize}px`}),
      class: _normalizeClass(["sidebar", _ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light'])
    }, [
      _createVNode(_component_SideBar, {
        onLogout: _ctx.logout,
        user: _ctx.user
      }, null, 8, ["onLogout", "user"])
    ], 6),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      style: _normalizeStyle({'font-size': `${_ctx.settingsStore.fontSize}px`}),
      class: _normalizeClass(["view", _ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light']),
      key: 'view'
    }, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    ], 6)), [
      [_vShow, !_ctx.loaderStore.loading]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", {
      style: _normalizeStyle({'font-size': `${_ctx.settingsStore.fontSize}px`}),
      key: 'spinner',
      class: _normalizeClass(["spinner", _ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light'])
    }, [
      _createVNode(_component_VueSpinner, {
        size: "40",
        color: "#004AAD"
      })
    ], 6)), [
      [_vShow, _ctx.loaderStore.loading]
    ]),
    _createVNode(_component_ModalComponent, {
      class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
    }, null, 8, ["class"]),
    _createVNode(_component_ToastsComponent)
  ], 64))
}