<template>
  <h3>Add a new Data product</h3>
  <div @click="(e) => closeOtherMultiSelect(e, false)" class="data-product">
    <p>
      Choose a Data product type:
    </p>
    <select
        style="width: auto; font-weight: 700"
        class="search-select"
        :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
        v-model="dataProductSelected"
    >
      <option
          v-for="product in dataProductsTypes"
          :style="{
                'color': settingsStore.colorScheme === 'dark' ? '#ffffff' : '#282828',
                'background-color':  settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }"
          :key="product"
          :value="product.value?.value">
        {{product.value.label}}
      </option>
    </select>
  </div>
  <div @click="(e) => closeOtherMultiSelect(e, false)" class="product-form">
    <div v-for="(field, index) in ['name', 'description']"
         :key="field"
         :class="field"
    >
      <input
          :tabindex="index"
          :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
          class="search-input"
          v-if="field === 'name'"
          :placeholder="`${newDataProduct[field].label} ${newDataProduct[field].required? '*' : ''}`"
          v-model="newDataProduct[field].value"
          type="text"
      >
      <textarea
          style="height: 100%; resize: none; background-color: transparent"
          :style="{'color': settingsStore.colorScheme === 'dark' ? '#E6EAF6' : '#000000'}"
          :placeholder="`${newDataProduct[field].label} ${newDataProduct[field].required? '*' : ''}`"
          v-model="newDataProduct[field].value"
          class="search-input"
          v-if="field === 'description'"></textarea>
    </div>
    <div
        v-for="(field, index) in ['owner', 'glossary', 'tags']"
        :key="field"
        :class="field">
      <select
          :tabindex="index"
          class="search-select"
          v-if="field === 'owner'"
          :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
          v-model="newDataProduct.owner.value"
      >
        <option
            :style="{
                'color': settingsStore.colorScheme === 'dark' ? '#ffffff' : '#282828',
                'background-color':  settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }"
            selected
            :value="null">
          Owner *
        </option>
        <option
            v-for="option in newDataProduct.owner.options"
            :style="{
                'color': settingsStore.colorScheme === 'dark' ? '#ffffff' : '#282828',
                'background-color':  settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }"
            :key="option"
            :value="option">
          {{option}}
        </option>
      </select>
      <div class="search-input multi-select"
           :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
           @click.self="(e) => closeOtherMultiSelect(e, field)"
           v-if="field === 'glossary' || field === 'tags'">
        {{newDataProduct[field].value.length === 0 ? `${newDataProduct[field].label} ${newDataProduct[field].required ? '*' : ''}` : newDataProduct[field].value.toString()}}
        <a
            :style="{ 'transform' : newDataProduct[field].opened ? 'rotate(180deg)' : null}"
            class="multi-select_open"
        >
          <font-awesome-icon
              :icon="['fa', 'chevron-down']"
          />
        </a>

      </div>
      <ul
          class="multi-select_options"
          :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
          v-show="newDataProduct[field].opened">
        <li
            class="multi-select_option"
            :tabindex="index"
            v-for="op in newDataProduct[field].options" :key="op">
          <input class="multi-select_checkbox" style="cursor: pointer" type="checkbox" @change="(e) => updateMultiSelectValue(e, newDataProduct[field], op)"> {{ op }}
        </li>
      </ul>
    </div>

    <div
        v-for="form in Object.keys(newDataProduct[dataProductSelected])"
        :key="form"
    >
      <select
          :tabindex="index"
          class="search-select"
          v-if="newDataProduct[dataProductSelected][form].type === 'select'"
          :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
          v-model="newDataProduct[dataProductSelected][form].value"
      >
        <option
            :style="{
                'color': settingsStore.colorScheme === 'dark' ? '#ffffff' : '#282828',
                'background-color':  settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }"
            selected
            :value="null">
          {{ newDataProduct[dataProductSelected][form].label }} *
        </option>
        <option
            v-for="option in newDataProduct[dataProductSelected][form].options"
            :style="{
                'color': settingsStore.colorScheme === 'dark' ? '#ffffff' : '#282828',
                'background-color':  settingsStore.colorScheme === 'dark' ? '#282828' : '#ffffff'
                }"
            :key="option"
            :value="option">
          {{option}}
        </option>
      </select>
      <input
          :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
          class="search-input"
          :placeholder="`${newDataProduct[dataProductSelected][form].label} ${newDataProduct[dataProductSelected][form].required ? '*' : ''}`"
          v-if="newDataProduct[dataProductSelected][form].type === 'text'"
          v-model="newDataProduct[dataProductSelected][form].value"
          type="text">
      <div
          style="background: transparent"
          v-if="newDataProduct[dataProductSelected][form].type === 'file'"
          :class="settingsStore.colorScheme === 'dark' ? 'dark' : 'light'"
          class="search-input file"
      >
        <label :for="form">
          {{newDataProduct[dataProductSelected][form].label}}
          <font-awesome-icon
              :icon="['fa', 'upload']"
          />
        </label>
        <input
            :name="form"
            :id="form"
            :placeholder="`${newDataProduct[dataProductSelected][form].label} ${newDataProduct[dataProductSelected][form].required ? '*' : ''}`"
            type="file">
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: space-between; width: 100%; margin-top: 30px">
    <button @click="$emit('back')" class="confirm">Back</button>
    <button
        :disabled="checkForm()"
        @click="confirm()"
        class="cancel">Save</button>
  </div>
</template>

<script lang="ts">
import {defineComponent, reactive, ref} from "vue";
import DATA_PRODUCTS from "@/constants/data_product";
import {useSettings} from "@/stores/settings";
import {useModalStore} from "@/stores/modal";
import {useDataCatalogStore} from "@/stores/data_catalog";
import {useToastsStore} from "@/stores/toasts";

export default defineComponent({
  name: "NewDataProductModal",
  setup: () => {
    const settingsStore = useSettings()
    const dataProductSelected = ref('BATCH_FILE')
    const modalStore = useModalStore()
    const dataCatalogStore = useDataCatalogStore()
    const toastStore = useToastsStore()
    const newDataProduct = reactive({
      name: {
        required: true,
        label: 'Name',
        value: null
      },
      owner: {
        required: true,
        label: 'Owner',
        value: null,
        options: [
          'DomainAdministrator1',
          'ContinuumAdministrator1',
          'AerosUser1'
        ]
      },
      description: {
        required: true,
        label: 'Description',
        value: null
      },
      glossary: {
        required: true,
        label: 'Glossary terms',
        value: [] as string[],
        opened: false,
        options: [
          'Lorem',
          'Ipsum',
          'Dolor',
          'Sit'
        ]
      },
      tags: {
        required: false,
        label: 'Tags',
        value: [] as string[],
        opened: false,
        options: [
          'Lorem',
          'Ipsum',
          'Dolor',
          'Sit'
        ]
      },
      BATCH_FILE: {
        freshness: {
          required: false,
          label: 'Freshness',
          type: 'text',
          value: null,
        },
        mappings: {
          required: false,
          label: 'Mappings File',
          type: 'file',
          value: null,
        },
        pathToFile: {
          required: true,
          label: 'Path to file',
          type: 'text',
          value: null,
        },
        translationsFile: {
          required: false,
          label: 'Translations file',
          type: 'file',
          value: null,
        },
      },
      BATCH_RELATIONAL_DATABASE: {
        freshness: {
          required: false,
          label: 'Freshness',
          type: 'text',
          value: null,
        },
        mappings: {
          required: false,
          label: 'Mappings File',
          type: 'file',
          value: null,
        },
        dbUrl: {
          required: true,
          label: 'Database url',
          type: 'text',
          value: null,
        },
        translationsFile: {
          required: false,
          label: 'Translations file',
          type: 'file',
          value: null,
        }
      },
      STREAMING_KAFKA: {
        inputFormat: {
          required: true,
          label: 'Input format',
          type: 'select',
          options: ['XML', 'JSON', 'CSV'],
          value: null,
        },
        inputTopic: {
          required: true,
          label: 'Input topic',
          type: 'text',
          value: null,
        },
        host: {
          required: true,
          label: 'Host IP',
          type: 'text',
          value: null,
        },
        mappings: {
          required: false,
          label: 'Mappings File',
          type: 'file',
          value: null,
        },
        port: {
          required: true,
          label: 'Port',
          type: 'text',
          value: null,
        },
        translationsFile: {
          required: false,
          label: 'Translations file',
          type: 'file',
          value: null,
        },
      },
      STREAMING_MQTT: {
        inputFormat: {
          required: true,
          label: 'Input format',
          type: 'select',
          options: ['XML', 'JSON', 'CSV'],
          value: null,
        },
        inputTopic: {
          required: true,
          label: 'Input topic',
          type: 'text',
          value: null,
        },
        host: {
          required: true,
          label: 'Host IP',
          type: 'text',
          value: null,
        },
        mappings: {
          required: false,
          label: 'Mappings File',
          type: 'file',
          value: null,
        },
        port: {
          required: true,
          label: 'Port',
          type: 'text',
          value: null,
        },
        translationsFile: {
          required: false,
          label: 'Translations file',
          type: 'file',
          value: null,
        },
        protocol: {
          required: true,
          label: 'Protocol',
          type: 'select',
          options: ['TCP', 'UDP'],
          value: null,
        },

      }
    } as any)
    const dataProductsTypes = Object.keys(DATA_PRODUCTS).map((k: any) => {
      return {
        key: k,
        value: DATA_PRODUCTS[k as keyof object]
      }
    })
    return {
      dataProductsTypes,
      dataProductSelected,
      newDataProduct,
      settingsStore,
      dataCatalogStore,
      modalStore,
      toastStore
    }
  },
  methods: {
    updateMultiSelectValue(e: any, field: any, option: string) {
      if(e.target.checked) {
        field.value.push(option)
      } else {
        field.value = field.value.filter((op: string) => op !== option)
      }
    },
    closeOtherMultiSelect(e: any, field: any) {
      if(field) {
        if(field === 'glossary') {
          this.newDataProduct.tags.opened = false
        } else {
          this.newDataProduct.glossary.opened = false
        }
        this.newDataProduct[field]['opened'] = !this.newDataProduct[field]['opened']
      } else {
        // click outside of a multiselect
        if (!e.target.classList.contains('multi-select') &&
            !e.target.classList.contains('multi-select_options') &&
            !e.target.classList.contains('multi-select_option') &&
            !e.target.classList.contains('multi-select_checkbox')) {
          this.newDataProduct.tags.opened = false
          this.newDataProduct.glossary.opened = false
        }
      }
    },
    checkForm() {
      // The submission will be disabled until all the required fields are populated
      const checkValues = [
        'name',
        'description',
        'glossary',
        'owner'
      ]
      const checkStaticFields = Object.keys(this.newDataProduct)
          .filter(k => checkValues.includes(k))
          .find(field => (this.newDataProduct[field].value === null ||
              this.newDataProduct[field].value === []) &&
              this.newDataProduct[field].required)
      const checkDynamicFields = Object.keys(this.newDataProduct[this.dataProductSelected])
          .find(field => {
            return (this.newDataProduct[this.dataProductSelected][field].value === null ||
                    this.newDataProduct[this.dataProductSelected][field].value === []) &&
            this.newDataProduct[this.dataProductSelected][field].required
          })

      return checkDynamicFields !== undefined || checkStaticFields !== undefined
    },
    confirm() {
      const typeSelected = DATA_PRODUCTS[Object.keys(DATA_PRODUCTS)
          .find((k: any) => {
            return DATA_PRODUCTS[k as keyof object]['value'] === this.dataProductSelected
      }) as keyof object]
      const newProduct = {
        id: Math.random().toString(),
        header: this.newDataProduct.name.value,
        subtitle: typeSelected,
        owner: this.newDataProduct.owner.value,
        expanded: false,
        actions: [
          {
            icon: 'trash',
            onClick: () => {
              return this.modalStore.showModal({
                template: "delete",
                data: {
                  onConfirm: () => {
                    this.dataCatalogStore.deleteDataProduct(newProduct)
                        .then(() => {
                          this.toastStore.appendToast({label: `Product ${newProduct.header} deleted`, success: true ,data: {}, autoRemove: false})
                        })
                        .catch((error) => console.error(error))
                        .finally(() => {
                          this.modalStore.hideModal(null)
                          this.modalStore.$reset()
                        })
                  },
                  header: "dataCatalog.deleteDataProduct",
                  subtitle: newProduct.header,

                }
              })
            }
          }
        ] as any[],
        content: this.newDataProduct.description.value,
        metadata: [
            {
              key: "tags",
              label: "dataCatalog.tags",
              values: this.newDataProduct.tags.value
            },
            {
              key: "glossary",
              label: "dataCatalog.glossary",
              values: this.newDataProduct.glossary.value
            }
        ],
        hidden: Object.keys(this.newDataProduct[this.dataProductSelected]).map((p: any) => {
          return {
            key: this.newDataProduct[this.dataProductSelected][p as keyof object].label,
            value: this.newDataProduct[this.dataProductSelected][p as keyof object].value
          }
        })
      }
      console.log(newProduct)
      this.$emit('confirm', newProduct)
    }
  }
})
</script>

<style scoped>

.data-product {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  border-bottom: solid 1.5px #D9DBF1;
  align-items: center;
  padding-bottom: 15px;
}

.search-select,
.search-input {
  display: flex;
  gap: 5px;
  width: 100%;
  color: #004AAD;
  align-items: center;
  outline: none;
  padding: 5px;
  height: 46px;
  border-radius: 10px;
}

.product-form {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas:
      "name owner"
      "description glossary"
      "description tags";
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: left;
  margin-top: 25px;
}

.name {
  grid-area: name;
}

.description {
  grid-area: description;
}

.owner {
  grid-area: owner;
}

.glossary {
  grid-area: glossary;
  position: relative;
}

.tags {
  grid-area: tags;
}

.glossary,
.tags {
  position: relative;
}

.search-select {
  border-radius: 10px;
  border: 1px solid #979797;
}

.multi-select {
  position: relative;
  cursor: pointer;
  background: transparent;
}

.multi-select_options {
  position: absolute;
  top: 100%;
  width: 100%;
  list-style: none;
  cursor: pointer;
  padding-left: 0;
  margin-top: 10px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  background-color: #282828;
  z-index: 100;
  border-radius: 10px;
  border: 1px solid #979797;
}

.multi-select_option {
  padding: 10px;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 10px;
}

.multi-select_checkbox {
  width: 18px;
  height: 18px;
}

.multi-select_open {
  position: absolute;
  transition: all 150ms ease;
  right: 10px;
  font-size: 10px;
}

.search-input {
  border: 1px solid #979797;
  padding: 10px;
}

.search-input.file {
  background: transparent;
  display: grid;
  height: auto;
}

.search-input.file > label {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
}

.not-valid {
  border: solid 1px crimson;
}

.confirm , .cancel{
  border: none;
  outline: none;
  border-radius: 10px;
  height: 40px;
  width: 150px;
  /*margin-top: 10px;*/
  cursor: pointer;
  font-weight: bold;
}

input[type=file] {
  display: none;
}

.confirm {
  background: #D9DBF1;
  color: #004AAD;
}

.cancel {
  background: #004AAD;
  color: #FFFFFF;
  transition: opacity 150ms ease;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>