import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0139666e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"150px","padding-top":"15px"} }
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-between"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t(_ctx.data.header)), 1),
    _createElementVNode("p", _hoisted_1, [
      _createElementVNode("b", null, _toDisplayString(_ctx.$t("general.deleteConfirm")) + ": " + _toDisplayString(_ctx.data?.subtitle), 1),
      _createTextVNode("? ")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back'))),
        class: "cancel"
      }, "Back"),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('confirm', _ctx.data))),
        class: "confirm"
      }, "Confirm")
    ])
  ], 64))
}