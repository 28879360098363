// Aeros application roles

const ROLES = Object.freeze({
    iotServiceDeployer: 'IoT service deployer',
    domainAdministrator: 'Domain administrator',
    continuumAdministrator: 'Continuum administrator',
    dataProductOwner: 'Data product owner',
    verticalDeployer: 'Vertical deployer',
    aerosUser: 'aerOS user'
})

export default ROLES