import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c773320e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "requirements" }
const _hoisted_2 = ["onUpdate:modelValue"]
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { style: {"display":"flex","justify-content":"space-between","width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", {
      class: _normalizeClass(_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light')
    }, " IE " + _toDisplayString(_ctx.data.index) + " Network ports ", 3),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.portMappings, (port, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "requirement",
          key: port
        }, [
          _withDirectives(_createElementVNode("select", {
            class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
            style: {"width":"80px"},
            "onUpdate:modelValue": ($event: any) => ((port.protocol) = $event),
            name: "protocol",
            id: "protocol"
          }, [
            _createElementVNode("option", {
              style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#0F0F0F' : '#ffffff'
            }),
              value: "TCP"
            }, "TCP", 4),
            _createElementVNode("option", {
              style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#0F0F0F' : '#ffffff'
            }),
              value: "UDP"
            }, "UDP", 4),
            _createElementVNode("option", {
              style: _normalizeStyle({
                'color': _ctx.settingsStore.colorScheme === 'dark' ? '#ffffff' : '#000000',
                'background-color':  _ctx.settingsStore.colorScheme === 'dark' ? '#0F0F0F' : '#ffffff'
            }),
              value: "SCTP"
            }, "SCTP", 4)
          ], 10, _hoisted_2), [
            [_vModelSelect, port.protocol]
          ]),
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass([_ctx.settingsStore.colorScheme === 'dark' ? 'dark' : 'light', "stepper-input"]),
            "onUpdate:modelValue": ($event: any) => ((port.portNumber) = $event),
            type: "number",
            placeholder: "Port"
          }, null, 10, _hoisted_3), [
            [_vModelText, port.portNumber]
          ]),
          _createElementVNode("a", {
            class: "remove",
            onClick: ($event: any) => (_ctx.removePort(index))
          }, [
            _createVNode(_component_font_awesome_icon, { icon: "trash" })
          ], 8, _hoisted_4)
        ]))
      }), 128)),
      _createElementVNode("button", {
        class: "stepper-btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.addPortMapping()))
      }, "New network port")
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('back'))),
        class: "confirm"
      }, "Back"),
      _createElementVNode("button", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('confirm', {data: _ctx.portMappings, index: _ctx.data.index, field: 'networkPorts'}))),
        class: "cancel"
      }, "Save")
    ])
  ], 64))
}