import {defineStore} from "pinia";
import DATA_PRODUCTS from "@/constants/data_product";

export const useDataCatalogStore = defineStore('data_catalog', {
    state: () => ({
        // MOCK DATA
        data_catalog: [
            {
                id: "1",
                header: "Product 1",
                expanded: false,
                owner: "Domainadministrator1",
                actions: [] as any[],
                subtitle: DATA_PRODUCTS.MQTT,
                content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod\n" +
                    "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\n" +
                    "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,\n" +
                    "tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,",
                metadata: [
                    {
                        key: "tags",
                        label: "dataCatalog.tags",
                        values: [
                            "Lorem",
                            "ipsum dolor",
                            "sit amet",
                            "example",
                            "example2",
                            "LoremIpsumDolorSit"
                        ]
                    },
                    {
                        key: "glossary",
                        label: "dataCatalog.glossary",
                        values: [
                            "Lorem ipsum",
                            "dolor sit",
                            "amet"
                        ]
                    }
                ],
                hidden: [
                    {
                        key: 'Freshness',
                        value: '0.7s'
                    },
                    {
                        key: 'Database url',
                        value: 'http://example:27017'
                    },
                    {
                        key: 'Host IP',
                        value: 'http://example.com'
                    },
                    {
                        key: 'URL IP',
                        value: '0.0.0.0'
                    },
                    {
                        key: 'Example',
                        value: '0.0.0.0'
                    },
                    {
                        key: 'Example 2',
                        value: "Lorem ipsum dolor sit amet"
                    },
                ]
            },
            {
                id: "2",
                header: "Product 2",
                owner: "Domainadministrator1",
                subtitle: DATA_PRODUCTS.KAFKA,
                expanded: false,
                actions: [] as any[],
                content: "Lorem ipsum dolor sit amet",
                metadata: [
                    {
                        key: "tags",
                        label: "dataCatalog.tags",
                        values: [
                            "Lorem",
                            "ipsum dolor",
                            "sit amet"
                        ]
                    },
                    {
                        key: "glossary",
                        label: "dataCatalog.glossary",
                        values: [
                            "Lorem ipsum",
                            "dolor sit",
                            "amet"
                        ]
                    }
                ],
                hidden: [
                    {
                        key: 'Freshness',
                        value: '0.5s'
                    },
                    {
                        key: 'Database url',
                        value: 'http://example:27017'
                    }
                ]
            },
            {
                id: "3",
                header: "Product 3",
                subtitle: DATA_PRODUCTS.RELATIONAL,
                owner: "AerosUser1",
                expanded: false,
                actions: [] as any[],
                content: "Lorem ipsum dolor sit amet",
                metadata: [
                    {
                        key: "tags",
                        label: "dataCatalog.tags",
                        values: [
                            "Lorem",
                            "sit amet"
                        ]
                    },
                    {
                        key: "glossary",
                        label: "dataCatalog.glossary",
                        values: [
                            "Lorem ipsum"
                        ]
                    }
                ],
                hidden: [
                    {
                        key: 'Freshness',
                        value: '0.7s'
                    },
                    {
                        key: 'Database url',
                        value: 'http://example:27017'
                    },
                    {
                        key: 'Host IP',
                        value: 'http://example.com'
                    },
                    {
                        key: 'URL IP',
                        value: '0.0.0.0'
                    },
                    {
                        key: 'Protocol',
                        value: 'MQTT'
                    },
                    {
                        key: 'Host IP',
                        value: 'http://example.com'
                    },
                    {
                        key: 'URL IP',
                        value: '0.0.0.0'
                    },
                    {
                        key: 'Protocol',
                        value: 'MQTT'
                    },
                ]
            },
            {
                id: "4",
                header: "Product 4",
                subtitle: DATA_PRODUCTS.MQTT,
                owner: "Continuumadministrator1",
                expanded: false,
                actions: [] as any[],
                content: "Lorem ipsum dolor sit amet",
                metadata: [
                    {
                        key: "tags",
                        label: "dataCatalog.tags",
                        values: [
                            "sit amet"
                        ]
                    },
                    {
                        key: "glossary",
                        label: "dataCatalog.glossary",
                        values: [
                            "Lorem ipsum"
                        ]
                    },
                ],
                hidden: [
                    {
                        key: 'Freshness',
                        value: '0.7s'
                    },
                    {
                        key: 'Database url',
                        value: 'http://example:27017'
                    },
                    {
                        key: 'Host IP',
                        value: 'http://example.com'
                    },
                    {
                        key: 'URL IP',
                        value: '0.0.0.0'
                    },
                    {
                        key: 'Protocol',
                        value: 'MQTT'
                    },
                ]
            },
        ]
    }),
    getters: {
        getDataCatalog(state) {
            return state.data_catalog
        }
    },
    actions: {
        deleteDataProduct(product: any) {
            return Promise
                .resolve(this.$state.data_catalog = this.$state.data_catalog.filter(d => d.id !== product.id))
                .then(() => this.$state.data_catalog)
        },
        addDataProduct(product: any) {
            return Promise
                .resolve(this.$state.data_catalog.push(product))
                .then(() => this.$state.data_catalog)
                .catch(err => console.error(err))
        }
    }
})