// Aeros data products

const DATA_PRODUCTS = Object.freeze({
    DATA: {
        label: "Batch Data Products - Data files",
        value: "BATCH_FILE"
    },
    RELATIONAL: {
        label: "Batch Data Products - Relational Databases",
        value: "BATCH_RELATIONAL_DATABASE"
    },
    KAFKA: {
        label: "Streaming Data Products - Kafka Sources",
        value: "STREAMING_KAFKA"
    },
    MQTT: {
        label: "Streaming Data Products - MQTT Sources",
        value: "STREAMING_MQTT"
    }
})

export default DATA_PRODUCTS